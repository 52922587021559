<template>
    <div class="flex">
        <div class="flex-none bg-black">
            <img class="h-16 object-contain my-auto" :src="'https://jomo.news/api/' + this.$props.article.image.formats.small.url">
        </div>
        <div class="flex-1">
            <div class="w-full text-center">
                <span class="">{{ this.$props.article.title  }}</span><br>
                <div class="flex justify-between px-2">
                    <span class="">{{ this.$props.article.author  }}</span>
                    <span class="">{{ this.parseDate(this.$props.article.date) }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
 import { defineComponent } from "vue";
 import { Article } from "@/types.d";

 export default defineComponent({
     name: 'TopicArticles',
     props: {
         article: {
             type: Object as () => Article,
             required: true
         }
     },
     data: () => ({

     }),
     methods: {
         parseDate() {
             let datetime = this.$props.article.date
             return datetime.split('T')[0]
         }
     }
 })

</script>
