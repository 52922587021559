
 import { defineComponent } from "vue";
 import { Article } from "@/types.d";

 export default defineComponent({
     name: 'TopicArticles',
     props: {
         article: {
             type: Object as () => Article,
             required: true
         }
     },
     data: () => ({

     }),
     methods: {
         parseDate() {
             let datetime = this.$props.article.date
             return datetime.split('T')[0]
         }
     }
 })

