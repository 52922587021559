<template>
    <div class="container w-11/12 mx-auto">
        <div class="w-full text-center mb-4"><span class="prose-2xl">Articles for <u style="text-transform: capitalize;">{{ this.topic.name }}</u></span></div>
        <TopicArticles v-for="art in this.topic.articles" :key="art.id" :article="art" />
    </div>
</template>

<script lang="ts">
 import { defineComponent } from 'vue';
 import axios from "axios";
 import TopicArticles from "@/components/Topics/TopicArticles.vue";

 export default defineComponent({
     name: 'Topic',
     components: {
         TopicArticles,
     },
     data: () => ({
         loading: false,
         topic: {}
     }),
     methods: {
         getTopic() {
             this.loading = true
             axios.get(`https://jomo.news/api/topics/${this.$route.params.tid}`)
             .then((resp: any) => {
                 this.topic = JSON.parse(JSON.stringify(resp.data))
                 this.loading = false
             })
             .catch((err: any) => {
                 console.error(`Failed to get the topic with an ID of ${this.$route.params.tid}`)
             })
         }
     },
     mounted() {
         this.getTopic()
     }
 })
</script>
